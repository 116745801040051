import React from "react";

import { ProductReview } from "resources/AudiotekaApi";

import ReviewItem from "../ReviewItem/ReviewItem";
import { Root } from "./styled";

const ReviewsList = ({ limit, reviews, ...props }: { limit?: number; reviews: ProductReview[] }) => {
  const list = reviews || [];
  const reviewList = typeof limit === "number" ? list.slice(0, limit) : list;

  return (
    <Root {...props}>
      {reviewList.map((item) => (
        <ReviewItem key={item._embedded["app:review"]?.id} reviewItem={item} />
      ))}
    </Root>
  );
};

export default ReviewsList;
