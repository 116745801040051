import React from "react";

import { Audiobook } from "resources/AudiotekaApi";

import { DurationRow, TagsRow } from "./rows";
import { FormatRow } from "./format-row";
import { TypeRow } from "./type-row";
import { IsbnRow } from "./isbn-row";
import css from "./details.module.scss";

interface Props {
  audiobook: Audiobook;
}

export const Details = ({ audiobook }: Props) => {
  const duration = audiobook.duration ?? 0;
  const lectors = audiobook._embedded["app:lector"];
  const publishers = audiobook._embedded["app:publisher"];
  const categories = audiobook._embedded["app:category"];
  const translators = audiobook._embedded["app:translator"];

  return (
    <div className={css.details}>
      <table className={css.detailsTable}>
        <tbody>
          <TagsRow tags={lectors} tagName="lector" />
          <TagsRow tags={translators} tagName="translator" />
          <DurationRow duration={duration} />
          <TagsRow tags={publishers} tagName="publisher" />
          <TypeRow kind={audiobook.kind} />
          <FormatRow />
          <IsbnRow isbn={audiobook.isbn} />
          <TagsRow tags={categories} tagName="category" />
        </tbody>
      </table>
    </div>
  );
};
