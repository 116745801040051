import styled from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import { darkStrongAdditional } from "modules/DesignSystem/colors";
// TODO: docelowo do usunięcia
import { NewIcon as BaseIcon } from "components/NewIcon";
import Button from "modules/DesignSystem/components/Button";

export const Box = styled.div`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.04);
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  padding: 16px;
  position: relative;
  z-index: 2;

  @media (min-width: ${breakpoint.laptop}) {
    border-radius: 8px;
  }

  & + & {
    z-index: 1;
    @media (min-width: ${breakpoint.laptop}) {
      margin-top: 8px;
      z-index: 2;
    }
  }
`;

export const Icon = styled(BaseIcon)``;

export const ClubIconWrapper = styled.span`
  display: flex;
  margin-left: var(--column-gap);
  width: 120px;
  height: 30px;

  @media (min-width: ${breakpoint.tablet}) {
    width: 90px;
    height: 25px;
  }
`;

export const Title = styled.p<{ secondary?: boolean }>`
  --column-gap: ${({ secondary }) => (secondary ? "12px" : "8px")};
  align-items: center;
  color: ${({ secondary }) => (secondary ? "inherit" : darkStrongAdditional)};
  display: flex;
  font-size: ${({ secondary }) => (secondary ? "14px" : "16px")};
  font-weight: 700;
  justify-content: center;
  line-height: 18px;
  margin: 0;
  text-align: center;
  white-space: pre-wrap;

  ${Icon} {
    min-width: 24px;
    &:not(:first-child) {
      margin-left: var(--column-gap);
    }

    &:not(:last-child) {
      margin-right: var(--column-gap);
    }
  }
`;

export const ActionBtn = styled(Button)<{ secondary?: boolean }>`
  align-items: center;
  display: flex;
  grid-gap: 4px;
  justify-content: center;
  line-height: 20px;
  padding: 13px;
  text-transform: none;

  & + & {
    margin-top: -4px;
  }
`;

export const Price = styled.p<{ club?: boolean; old?: boolean }>`
  align-items: baseline;
  color: ${({ club, old }) => (club ? "#d518ff" : old ? "#bbb7c5" : "inherit")};
  display: flex;
  font-size: ${({ old }) => (old ? "18px" : "32px")};
  font-weight: ${({ old }) => (old ? 400 : 600)};
  justify-content: center;
  margin: 0;
  text-decoration: ${({ old }) => (old ? "line-through" : "none")};

  & > & {
    margin-left: 8px;
  }
`;

export const Trial = styled.p`
  color: ${darkStrongAdditional};
  font-size: 12px;
  margin: 0;
  text-align: center;
`;

export const LowestPrice = styled.p`
  color: #757575;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  text-align: center;
`;
