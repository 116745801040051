import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslations } from "next-intl";

import { Link } from "components";
import { useUser } from "modules/user";
import { Audiobook, getUserProductReview, ProductReview } from "resources/AudiotekaApi";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";

import ReviewsList from "../ReviewsList";
import RatingBadge from "../RatingBadge";
import { AddRateBtn, Rate, Rating, Reviews, ReviewsInfo, ReviewsMore, Root, Title, Top } from "./styled";
import ReviewsSlideBar from "./ReviewsSlideBar/ReviewsSlideBar";
import ReviewFormSlideBar from "./ReviewFormSlideBar/ReviewFormSlideBar";

interface Props {
  canRate: boolean;
  isPreLogged: boolean;
  audiobook: Audiobook;
}

const ReviewsBox = forwardRef<HTMLDivElement, Props>(({ canRate, isPreLogged, audiobook }, ref) => {
  const rating = Math.round(audiobook.rating * 10) / 10;
  const ratingCount = audiobook.rating_count;

  const t = useTranslations();
  const { hal: user, isLoading: isLoadingUser } = useUser();
  const { signInRedirectParams } = useSignInRedirectParams();

  const [formVisible, setFormVisible] = useState(false);
  const [moreVisible, setMoreVisible] = useState(false);
  const [review, setReview] = useState<ProductReview | null>(null);

  const updateReview = useCallback(
    async (newReview?: ProductReview) => {
      if (newReview) {
        setReview(newReview);
      } else if (user) {
        try {
          const existingReview = await getUserProductReview(audiobook.id);
          setReview(existingReview);
        } catch {
          setReview(null);
        }
      } else {
        setReview(null);
      }
    },
    [user, audiobook]
  );

  useEffect(() => {
    if (!isLoadingUser && user) {
      updateReview();
    }
  }, [isLoadingUser, updateReview, user]);

  const toggleFormVisible = useCallback(() => {
    if (!formVisible && review && (review._embedded?.["app:review"] || review._embedded?.["app:rating"])) {
      setMoreVisible(true);
    } else {
      setFormVisible((visible) => !visible);
    }
  }, [formVisible, review]);

  const toggleMoreVisible = useCallback(() => {
    setMoreVisible((visible) => !visible);
  }, []);

  const handleRateBtn = useCallback(() => {
    setMoreVisible(false);
    setFormVisible(true);
  }, []);

  const handleReviewSubmit = useCallback(async (newReview: ProductReview) => {
    await updateReview(newReview);

    setFormVisible(false);
    setMoreVisible(true);
  }, []);

  const reviewBaseList = audiobook._embedded["app:user-product-review"];

  return (
    <Root ref={ref}>
      <Top>
        <Title>{t("product.reviews.title")}</Title>
        <Rate inactive={!canRate && isPreLogged} wide={!canRate}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {canRate ? (
            <AddRateBtn onClick={toggleFormVisible}>{t("product.reviews.add_rating")}</AddRateBtn>
          ) : isPreLogged ? (
            <p>{t("product.reviews.buy_to_rate")}</p>
          ) : (
            <p>
              {t.rich("product.reviews.login_to_rate", {
                // eslint-disable-next-line react/no-unstable-nested-components
                link1: (chunks) => (
                  <Link route="signIn" params={signInRedirectParams}>
                    {chunks}
                  </Link>
                ),
              })}
            </p>
          )}
        </Rate>
      </Top>
      <Rating>
        <RatingBadge average={rating} count={ratingCount} />
      </Rating>
      {reviewBaseList.length > 0 && (
        <>
          <ReviewsInfo>
            {t.rich("product.reviews.moderation_info", {
              // eslint-disable-next-line react/no-unstable-nested-components
              link1: (chunks) => (
                <Link route="reviewTerms" target="_blank">
                  {chunks}
                </Link>
              ),
            })}
          </ReviewsInfo>
          <Reviews>
            <ReviewsList reviews={reviewBaseList} limit={3} />
            {reviewBaseList.length > 3 && (
              <ReviewsMore onClick={toggleMoreVisible}>{t("product.reviews.show_all")}</ReviewsMore>
            )}
          </Reviews>
        </>
      )}

      <ReviewsSlideBar
        canRate={canRate}
        isPreLogged={isPreLogged}
        open={moreVisible}
        onClose={toggleMoreVisible}
        onRate={handleRateBtn}
        audiobook={audiobook}
        review={review}
      />

      <ReviewFormSlideBar
        audiobook={audiobook}
        open={formVisible}
        review={review}
        onClose={toggleFormVisible}
        onReview={handleReviewSubmit}
      />
    </Root>
  );
});

export default ReviewsBox;
