import React from "react";
import { useTranslations } from "next-intl";

import { ButtonVariant } from "modules/DesignSystem/components/Button";
import { Audiobook } from "resources/AudiotekaApi";

import { Icon, ActionBtn, Box, Title } from "../Boxes.styled";
import { InfoIcon } from "../InfoIcon";
import { DownloadBox } from "./Download/DownloadBox";
import { PlayButton } from "../Buttons";

interface Props {
  audiobook: Audiobook;
  canDownload: boolean;
}

export const ProductOnShelf = ({ audiobook, canDownload }: Props) => {
  const t = useTranslations();

  return (
    <>
      <Box>
        <Title>
          <Icon icon="shelf" width="24" height="24" />
          {t("product.price_box.title.shelf")}
          {canDownload && <InfoIcon info={t("product.price_box.info.shelf_mp3")} />}
        </Title>
        <PlayButton audiobook={audiobook} />
        <ActionBtn variant={ButtonVariant.outlined} route="shelf">
          <Icon icon="shelf" width="20" height="20" />
          {t("product.price_box.cta.shelf")}
        </ActionBtn>
      </Box>
      {canDownload && <DownloadBox audiobook={audiobook} />}
    </>
  );
};
