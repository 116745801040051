import React from "react";
import { useLocale } from "next-intl";

const dictionary = {
  cz: { label: "Formát", value: "digitální" },
  sk: { label: "Formát", value: "MP3 na stiahnutie" },
  de: { label: "Format", value: "Hörbuch Download" },
};

export function FormatRow() {
  const locale = useLocale();

  const format = dictionary[locale];

  if (!format) return null;

  return (
    <tr>
      <td>{format.label}</td>
      <td>{format.value}</td>
    </tr>
  );
}
