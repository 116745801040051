import React from "react";
import { useLocale } from "next-intl";

export function IsbnRow({ isbn }: { isbn: string }) {
  const locale = useLocale();

  if (locale !== "de" && locale !== "lt") return null;
  if (!isbn) return null;

  return (
    <tr>
      <td>ISBN</td>
      <td>{isbn}</td>
    </tr>
  );
}
