import React from "react";
import { useTranslations } from "next-intl";

import { usePlayer } from "modules/player";
import { NewIcon } from "components/NewIcon";
import { Audiobook } from "resources/AudiotekaApi";

import { Button, Label, Sample, Title } from "./PlayButton.styled";

export const PlayButton = ({ audiobook, canListen }: { audiobook: Audiobook; canListen: boolean }) => {
  const t = useTranslations();
  const player = usePlayer();

  const handlePlay = async () => {
    if (player.audiobook?.id !== audiobook.id) {
      await player.load(audiobook.id, { autoPlay: true });
    } else {
      player.play();
    }
  };

  return (
    <Button onClick={handlePlay}>
      <Label>
        <Title>{t("product.listen")}</Title>
        {canListen ? null : (
          <Sample>{t("product.sample", { sampleLength: audiobook.sample_duration_in_minutes })}</Sample>
        )}
      </Label>
      <NewIcon icon="play" width="24" height="24" />
    </Button>
  );
};
