import styled from "styled-components";
import { breakpoint } from "modules/DesignSystem/breakpoint";

export const Wrapper = styled.div`
  position: relative;
`;

export const Content = styled.div<{ dark?: boolean }>`
  background: ${({ dark }) => (dark ? "rgba(0,0,0,.3)" : "#fff")};
  border-radius: 16px 16px 0 0;
  position: relative;
  z-index: 2;

  @media (min-width: ${breakpoint.laptop}) {
    padding-top: 32px;
    border-radius: 24px 24px 0 0;
  }
`;

export const Details = styled.section``;

export const Main = styled.div<{ blur: boolean; dark: boolean; img: string }>`
  isolation: isolate;
  background: ${({ dark }) => (dark ? "#222" : "#fff")};
  color: ${({ dark }) => (dark ? "#fff" : "#000")};
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  &::before {
    background: ${({ dark, img }) =>
      `${!dark ? "linear-gradient(rgba(22,8,41,.4), #160829), " : ""}url("${img}") center top no-repeat`};
    background-size: cover;
    bottom: 0;
    content: "";
    filter: ${({ blur }) => (blur ? "blur(25px) brightness(.8)" : "none")};
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(1.1);
  }

  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${breakpoint.desktop}) {
    margin-bottom: 90px;
  }
`;

export const SectionSeparator = styled.div`
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
`;

export const PriceContainer = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoint.laptop}) {
    display: none;
  }
`;
