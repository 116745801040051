import React, { ComponentProps } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import * as Sentry from "@sentry/nextjs";
import { JSONSchema7Object } from "json-schema";

import { buildLink } from "utils/buildLink";
import { isId, parsePrice } from "utils/string";
import { cutText } from "modules/Audiobook/utils";
import { slugs } from "modules/Audiobook/slugs";
import { Audiobook } from "modules/Audiobook";
import { Breadcrumbs } from "app-components/breadcrumbs";
import { getLocationInfo } from "utils/getLocationInfo";
import { Audiobook as AudiobookType, getAudiobook } from "resources/AudiotekaApi";

interface Props extends ComponentProps<typeof Audiobook> {
  categoryName: string;
  breadcrumbs: ComponentProps<typeof Breadcrumbs>["items"];
}

const getNames = (audiobook: AudiobookType, key: "author" | "publisher" | "lector") =>
  audiobook._embedded[`app:${key}`].map((data) => data.name).join(", ");

export default function AudiobookPage({
  audiobook,
  breadcrumbs,
  categoryName,
  currency,
  darkMode,
  linkReplace,
}: Props) {
  const t = useTranslations();
  const { asPath } = useRouter();

  const authors = getNames(audiobook, "author");
  const publishers = getNames(audiobook, "publisher");

  const schema: JSONSchema7Object = {
    "@context": "http://schema.org",
    "@type": "Audiobook",
    name: audiobook.name,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: audiobook.rating || 0,
      ratingCount: audiobook.rating_count,
    },
    offers: audiobook.price
      ? {
          "@type": "Offer",
          price: parsePrice(audiobook.price_for_subscribers || audiobook.price),
          priceCurrency: currency,
        }
      : undefined,
    author: authors,
    publisher: publishers,
    readBy: getNames(audiobook, "lector"),
    datePublished: audiobook.published_at,
  };

  return (
    <>
      <Head>
        <title>{t("meta.product.title", { product: audiobook.name, publisher: publishers })}</title>
        <meta
          name="description"
          content={t("meta.product.description", {
            product: audiobook.name,
            author: authors,
            category: categoryName,
          })}
        />
        <meta name="keywords" content={authors} />

        <meta property="og:url" content={`${process.env.SITE_URL}${asPath}`} />
        <meta key="og-title" property="og:title" content={`${audiobook.name} - ${authors} | Audioteka`} />
        <meta key="og-description" property="og:description" content={cutText(audiobook.description || "", 50)} />
        <meta key="og-image" property="og:image" content={`${audiobook.image_url}?auto=format&w=500`} />
        <meta property="og:site_name" content="Audioteka" />
        <meta property="og:type" content="Audiobook" />

        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
      </Head>
      <Breadcrumbs items={breadcrumbs} />
      <Audiobook audiobook={audiobook} currency={currency} darkMode={darkMode} linkReplace={linkReplace} />
    </>
  );
}

AudiobookPage.layoutConfig = {
  catalogData: true,
};

export const getServerSideProps = async ({ query }) => {
  const redirectableLocales = ["cz", "de", "lt", "sk"] as const;
  const shouldBeRedirected = redirectableLocales.some((locale) => query.locale === locale && slugs[locale][query.id]);

  if (shouldBeRedirected) {
    return {
      redirect: {
        permanent: true,
        destination: buildLink("audiobook", query.locale, { id: slugs[query.locale][query.id] }),
      },
    };
  }

  let apiQueryId = query.id;

  if (!isId(apiQueryId)) {
    // Reference ID always starts with language and underscore.
    apiQueryId = /^[a-z]{2}_/i.test(apiQueryId) ? `reference-id/${apiQueryId}` : `slug/${apiQueryId}`;
  }

  try {
    const { catalogId, currency } = getLocationInfo(query.locale);
    const audiobook = await getAudiobook(apiQueryId, catalogId);

    if (!audiobook || !audiobook.id) {
      return {
        notFound: true,
        props: {},
      };
    }

    const productId = audiobook.slug || audiobook.id;

    if (query.id !== productId) {
      return {
        redirect: {
          permanent: true,
          destination: buildLink("audiobook", query.locale, { id: productId }),
        },
      };
    }

    return {
      props: {
        audiobook,
        darkMode: false,
        currency,
      },
    };
  } catch (error) {
    if (error.response?.status === 404) {
      return {
        notFound: true,
        props: {},
      };
    }

    Sentry.captureException(error);
    throw error;
  }
};
