import React from "react";
import { useTranslations } from "next-intl";

export function TypeRow({ kind }: { kind: string }) {
  const t = useTranslations();

  if (!kind) return null;

  return (
    <tr>
      <td>{t("product.type")}</td>
      <td>{kind}</td>
    </tr>
  );
}
