import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import dynamic from "next/dynamic";

import { useUser } from "modules/user";
import Alert from "modules/DesignSystem/components/Alert";
import { Grid } from "components";
import { HtmlToReact } from "components/html-to-react";
import { Audiobook as AudiobookType, getLicensesChannels, LicenseChannels } from "resources/AudiotekaApi";

import ReviewsBox from "./components/ReviewsBox";
import { NotAvailable, PriceBox } from "./components/PriceBox";
import { PriceBoxDesktop } from "./components/PriceBoxDesktop";
import { ProductTop } from "./components/ProductTop";
import Description from "./components/description/description";
import { useAnalytics } from "./useAnalytics";
import { Content, Details, Main, PriceContainer, SectionSeparator, Wrapper } from "./Audiobook.styled";

const AttachmentList = dynamic(() => import("./components/AttachmentList"), { ssr: false });

interface Props {
  audiobook: AudiobookType;
  darkMode?: boolean;
  linkReplace: string | null;
  currency: string;
}

export const Audiobook = ({ audiobook, darkMode, linkReplace, currency }: Props) => {
  const reviewsRef = useRef<HTMLDivElement>();
  const mainRef = useRef<HTMLDivElement>();

  const { isLoading: isLoadingUser, isLoggedIn, isClubMember } = useUser();

  const [licenses, setLicenses] = useState<LicenseChannels | undefined>(undefined);

  useAnalytics(audiobook, currency);

  const [canListen, canRate] = useMemo((): [boolean, boolean] => {
    if (!licenses) {
      return [false, false];
    }

    const licenseStates = Object.values(licenses).map((license) => license.state);
    const _canListen = licenseStates.includes("CAN_LISTEN");

    return [_canListen, _canListen || licenseStates.includes("NOT_AVAILABLE_ON_THIS_DEVICE")];
  }, [licenses]);

  const isAvailable =
    canListen ||
    (audiobook._embedded["app:context"].is_enabled &&
      (!!audiobook.price ||
        !!audiobook.price_for_subscribers ||
        !!audiobook.is_available_in_subscription ||
        audiobook.is_free));

  useEffect(() => {
    if (linkReplace && window.location.pathname !== linkReplace) {
      window.history.replaceState(window.history.state, window.document.title, linkReplace);
    }
  }, [linkReplace]);

  useEffect(() => {
    async function loadLicensesChannels() {
      try {
        const licensesChannels = await getLicensesChannels(audiobook.id);
        setLicenses(licensesChannels);
      } catch {
        setLicenses(undefined);
      }
    }

    if (isLoadingUser) {
      return;
    }

    if (isLoggedIn) {
      loadLicensesChannels();
    } else {
      setLicenses(undefined);
    }
  }, [isLoadingUser, isLoggedIn, audiobook]);

  const scrollToReviews = useCallback(() => {
    if (reviewsRef.current) {
      window.scrollBy({
        behavior: "smooth",
        top: reviewsRef.current.getBoundingClientRect().top - 70,
      });
    }
  }, [reviewsRef.current]);

  const priceBox = isAvailable ? (
    <PriceBox audiobook={audiobook} licenses={licenses} isClubMember={isClubMember} />
  ) : (
    <NotAvailable />
  );

  const alert = audiobook.web_alert || audiobook.alert;
  const attachmentList = audiobook._embedded["app:attachment"];

  return (
    <Wrapper>
      <PriceBoxDesktop mainRef={mainRef}>{priceBox}</PriceBoxDesktop>
      <Main blur={!darkMode} dark={darkMode} img={`${audiobook.image_url}?w=300&auto=format`} ref={mainRef}>
        <ProductTop audiobook={audiobook} available={isAvailable} canListen={canListen} onReviews={scrollToReviews} />
        <Content dark={darkMode}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Column span={{ tablet: 6 }} $offset={{ tablet: 4 }}>
                <PriceContainer>{priceBox}</PriceContainer>
              </Grid.Column>
              <Grid.Column span={{ laptop: 8 }}>
                <Details>
                  {alert && (
                    <Alert centered mb={2}>
                      <HtmlToReact html={alert} />
                    </Alert>
                  )}
                  <Description audiobook={audiobook} />
                  {attachmentList.length > 0 && canListen && <AttachmentList list={attachmentList} />}
                  <SectionSeparator />
                  <ReviewsBox canRate={canRate} isPreLogged={isLoggedIn} audiobook={audiobook} ref={reviewsRef} />
                </Details>
              </Grid.Column>
            </Grid.Row>
          </Grid.Container>
        </Content>
      </Main>
    </Wrapper>
  );
};
