import React, { useEffect, useRef } from "react";

import { Container } from "app-components/container";
import { NewIcon } from "components/NewIcon";
import { Link } from "app-components/link";

import { BreadcrumbsItem } from "./breadcrumbs.types";
import css from "./breadcrumbs.module.scss";

interface Props {
  items: BreadcrumbsItem[];
}

export function Breadcrumbs({ items }: Props) {
  const listRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({ behavior: "auto", left: listRef.current.scrollWidth });
    }
  }, [items]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={css.breadcrumbs}>
      <Container className={css.list} ref={listRef}>
        <Link className={css.item} route="home">
          <NewIcon icon="home" width="18" height="18" />
        </Link>

        {items.map((item) => (
          <React.Fragment key={item.name}>
            <NewIcon className={css.separator} icon="arrow" width="14" height="14" />
            {item.link ? (
              <Link className={css.item} {...item.link}>
                {item.name}
              </Link>
            ) : (
              <span className={css.item}>{item.name}</span>
            )}
          </React.Fragment>
        ))}
      </Container>
    </div>
  );
}
