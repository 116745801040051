import React from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import { NewIcon } from "components/NewIcon";
import { Audiobook } from "resources/AudiotekaApi";

import { Options } from "../../Options";

const DownloadModal = dynamic(() => import("./DownloadModal"), { ssr: false });

const Header = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 4px auto 12px;
  text-align: center;

  @media (min-width: ${breakpoint.laptop}) {
    width: 240px;
  }
`;

const DownloadButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: #33adff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 4px;
  grid-template-columns: auto 1fr;
  justify-content: center;

  &:hover {
    color: #5bbdff;
  }
`;

const Box = styled.div`
  display: grid;
  gap: 8px;
`;

interface Props {
  audiobook: Audiobook;
}

export const DownloadBox = ({ audiobook }: Props) => {
  const t = useTranslations();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <Options summary={t("product.price_box.options.general")}>
      <Box>
        <Header>{t("product.price_box.mp3.text")}</Header>
        <DownloadButton onClick={() => setIsModalVisible(true)}>
          <NewIcon icon="download" width="16" height="16" />
          {t("product.price_box.mp3.download", { fileSize: 17.7 })}
        </DownloadButton>
        {isModalVisible && (
          <DownloadModal onClose={() => setIsModalVisible(false)} open={isModalVisible} audiobook={audiobook} />
        )}
      </Box>
    </Options>
  );
};
