import { useLocale } from "next-intl";
import React, { type SVGProps } from "react";

type Version = "one-line" | "two-line";

export const KlubLogo = ({ version, ...props }: SVGProps<SVGSVGElement> & { version: Version }) => {
  const locale = useLocale();
  const isClub = locale === "de" || locale === "lt";

  return (
    <svg {...props}>
      <use href={`/img/klub-logo.svg#${version}-${isClub ? "c" : "k"}lub`} />
    </svg>
  );
};
